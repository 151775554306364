import React from 'react';

import { Seo } from 'views/components/seo';
import { Root } from './index.styled';

const FourOFour = () => (
  <Root>
    404: Not Found
  </Root>
);

export default FourOFour;
export const Head = () => <Seo title="404: Not Found" />;